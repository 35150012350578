import { Component, OnInit } from '@angular/core';
import { LoadService } from 'src/app/core/services/load.service';
import { OfferService } from 'src/app/core/services/offer.service';
import { environment } from 'src/environments/environment';
import { SesionService } from 'src/app/core/services/sesion.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';

declare var $: any;

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

	step1 = true;
	step2 = false;

	constructor(
		public load: LoadService,
		public offerService: OfferService,
		public sesion: SesionService,
		public broker: BrokerService,
		public router: Router,
		public utilities : UtilitiesService,
		private cookieService: CookieService,
		private dataLayerService: DataLayersService

	) { }

	ngOnInit() {
	}

	close() {
		this.load.close("#notRequirementModal");
		this.offerService.offerRequirement = true;
	}

	steptyC(a: any) {
		if (a == 1) {
			this.step1 = false;
			this.step2 = true;
		} else {
			this.step1 = true;
			this.step2 = false;
		}
	}
	closeSession() {
		// $("#termsAndConditions").on('hidden.bs.modal', function () {
		// 	$(this).data('bs.modal', null);
		// });
		const body = {
			clientId: environment.clientId,
			idSession: this.sesion.sesionCookie,
			country: environment.country
		};
		this.broker.cerrar_sesion(body).subscribe((response: any) => {
			this.borrarData();
		},
			(error) => {
				this.borrarData();
			});
		//$(".modal-backdrop").css({"opacity": "0", "display": "none"});
	}

	borrarData() {
		window.localStorage.clear();
		this.cookieService.delete('IDSESSIONMDC', this.sesion.sesionCookie, environment.domain);
		this.sesion.sesionCookie = null;
		window.location.href = "/";
		//this.router.navigate(['/']);
	}

	saveTerms() {
		const data2 = {
			session: this.sesion.sesionCookie,
			termCondition: 12
		};

		this.broker.guardarTerminos(data2).subscribe((resultado: any) => {
			$(".modal-backdrop").css({ "opacity": "0", "display": "none" });
		}, error => {
		})
	}
	closeModalNotRequi() {
		this.load.close("#notRequirementModal");
		this.offerService.offerRequirement = true;
	}

	closeModalComments() {
		this.load.close("#comments");
	}

	closeModalNoOFertas() {
		this.load.close("#noOfertasModal");
	}

	closeModalConfirmationV2() {
		this.load.close("#confirmationModalV2");
	}

	updateUserInfo() {
		// let companyId = localStorage.getItem('companyId');
		// let offerId = localStorage.getItem('offerId');
		let companyId = this.offerService.offerSelected.company.id;
		let offerId = this.offerService.offerSelected.offerId;
		let sourceOfTraffic = '';
		let campaignOfTraffic = '';
		let mediumOfTraffic = '';
		// let PreselectaId = (companyId == null ? '' : companyId) + (offerId == null ? '' : offerId);
		if((Object.keys(this.utilities.utmParams).length > 0)){
			for (const key in this.utilities.utmParams) {
				if (key.toLowerCase() == 'utm_source') {
					sourceOfTraffic = this.utilities.utmParams[key]
				}
				if (key.toLowerCase() == 'utm_medium') {
					mediumOfTraffic = this.utilities.utmParams[key]
				}
				if (key.toLowerCase() == 'utm_campaign') {
					campaignOfTraffic = this.utilities.utmParams[key]
				}
			  }
		}
		let dataOferta = {
			idSession: this.sesion.sesionCookie,
			companyId: companyId,
			offerId: offerId,
			sourceOfTraffic : sourceOfTraffic,
			campaignOfTraffic : campaignOfTraffic,
			mediumOfTraffic : mediumOfTraffic
		}
		const body = {
			idSession: this.cookieService.get('IDSESSIONMDC'),
			cellPhone: this.sesion.userPhone,
			country: "CO"
		}
		this.load.enableButtons = "none";
		this.load.openMicro('estamos gestionando tu solicitud');
		this.broker.updateInfo(body).subscribe(
			(result: any) => {
				if (result.code == "200") {
					this.broker.aplicarOferta(dataOferta).subscribe((resultado: any) => {
						if (resultado.orderNumber) {
							let offerAux = this.offerService.offersList.find((ele: any) => ele.company.id == this.offerService.offerSelected.company.id && ele.offerId == this.offerService.offerSelected.offerId);

							offerAux.preselecta.orderNumber = resultado.orderNumber;
							offerAux.preselecta.date = resultado.applyDate;
							offerAux.preselecta.urlRedirect = resultado.applyDetail.urlRedirect;
							//  Oferta especial Davivivenda. 
							if (dataOferta.companyId == "560034313") {
								let dataOfertaDav = {
									idSession: this.sesion.sesionCookie,
									userAgent: "",
									idDevice: ""
								}
								this.broker.aplicarOfertaDav(dataOfertaDav).subscribe((resultado: any) => {
									if (resultado.body.includes("http")) {
										// GA4 - confirm_application
										this.dataLayerService.dl_eventoGA4_confirm_application(localStorage.getItem('productName'), offerId, localStorage.getItem('companyName'), 'Actualizar y Continuar');
										window.location.href = encodeURI(resultado.body);
									} else {
										this.router.navigate(['/error']);
									}
									// window.location.href = resultado.body;

									if (resultado.statusCodeValue == 400) {
										this.load.open('#load-modal', '¡Error de aplicación!', 'No fue posible aplicar a esta oferta, te redirigiremos a otras ofertas para ti.', false, undefined);
										this.dataLayerService.dl_eventoGA4_confirm_application(localStorage.getItem('productName'), offerId, localStorage.getItem('companyName'), 'Actualización fallida');
										this.load.errorService();
									}
								});
								this.load.closePopUp('NoBtn');
								this.load.enableButtons = "block";
								this.load.closeMicro();
								this.closeModalConfirmationV2();
							} else {
								// this.offerService.offerApplied = resultado;
								this.offerService.offerSelected.isApplied = true;
								const urlRedirect = resultado.applyDetail.urlRedirect;
								const urlEncriptedData = resultado.applyDetail.urlEncriptedData;
								// GA4 - confirm_application
								this.dataLayerService.dl_eventoGA4_confirm_application(localStorage.getItem('productName'), offerId, localStorage.getItem('companyName'), 'Actualizar y Continuar');
								if (urlRedirect != "" || urlRedirect != undefined) {
									let urlRedirect = resultado.applyDetail.urlRedirect;

									// Solo para aliado Credissimo
									if (dataOferta.companyId == "901146443") {
										window.location.href = urlRedirect + '?bon=' + resultado.orderNumber;
									}
									// Solo para aliado Solventa
									else if (dataOferta.companyId == "901255144") {
										this.broker.sendEncriptedData(body, urlEncriptedData + resultado.encryptedInfo);
										window.location.href = urlRedirect;
									}
									// Para todos
									else {
										if (this.hasQueryString(urlRedirect)) {
											urlRedirect = urlRedirect + '&lead=' + encodeURIComponent(resultado.encryptedInfo);
										}
										else {
											urlRedirect = urlRedirect + '?lead=' + encodeURIComponent(resultado.encryptedInfo);
										}
										offerAux.preselecta.urlRedirect = urlRedirect;
										// this.dataPreselecta.urlRedirect = urlRedirect;

										window.location.href = urlRedirect;
									}
								}

								this.load.closePopUp('NoBtn');
								this.load.enableButtons = "block";
								this.load.closeMicro();
								this.closeModalConfirmationV2();
							}
						} else {
							this.load.errorService();
							this.dataLayerService.dl_eventoGA4_confirm_application(localStorage.getItem('productName'), offerId, localStorage.getItem('companyName'), 'Actualización fallida');
						}
					}, error => {
						this.load.errorService();
						this.dataLayerService.dl_eventoGA4_confirm_application(localStorage.getItem('productName'), offerId, localStorage.getItem('companyName'), 'Actualización fallida');
					});
				}
				else {
					this.load.errorService();
					this.dataLayerService.dl_eventoGA4_confirm_application(localStorage.getItem('productName'), offerId, localStorage.getItem('companyName'), 'Actualización fallida');
				}
			},
			(error: any) => {
				this.load.errorService();
				this.dataLayerService.dl_eventoGA4_confirm_application(localStorage.getItem('productName'), offerId, localStorage.getItem('companyName'), 'Actualización fallida');
			});

	}

	hasQueryString(url: any) {
		let reg = new RegExp('[?&][a-zA-Z]', 'g');
		let queryString = reg.exec(url);
		if (queryString != null) {
			return queryString.length > 0 ? true : false;
		}
		return false;
	}

}
