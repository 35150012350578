import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

// Módulos
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgxCurrencyModule } from 'ngx-currency';
import { AppRoutingModule } from './app-routing.module';

// Pipes
import { CurrencyFormat } from './core/pipes/currencyFormat.pipe';

// Directivas
import { LazyLoadImgDirective } from './core/directives/lazy-load-img.directive';

// Servicios
import { CookieService } from 'ngx-cookie-service';
import { CanActiveViaAuthGuard } from './core/services/auth-guard.service';

// Componentes
import { AppComponent } from './app.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HeaderComponent } from './shared/header/header.component';
import { LandingOfferComponent } from './pages/landing-offer/landing-offer.component';
import { EducationBlogComponent } from './pages/education-blog/education-blog.component';
import { EducationBlogPostComponent } from './pages/education-blog/education-blog-post/education-blog-post.component';
import { SharedModule } from "./modules/shared/shared.module";
import { ModalComponent } from './shared/modal/modal.component';
import { LoaderComponent } from './shared/loader/loader.component';

registerLocaleData(localeEs, 'es');

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: false,
  decimal: ".",
  precision: 0,
  prefix: "$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    CurrencyFormat,
    ErrorPageComponent,
    SitemapComponent,
    FaqComponent,
    HeaderComponent,
    LazyLoadImgDirective,
    LandingOfferComponent,
    EducationBlogComponent,
    EducationBlogPostComponent,
    ModalComponent,
    LoaderComponent
  ],
  imports: [
    // AngularFontAwesomeModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    BrowserModule,
    AppRoutingModule,
    RecaptchaModule,
    SharedModule
],
  providers: [
    CanActiveViaAuthGuard,
    CookieService,
    { provide: LOCALE_ID, useValue: 'es' }
  ],
  // {provide: ErrorHandler, useClass : ErrorHandlerService}
  bootstrap: [AppComponent]
})
export class AppModule { }
