<div class="main-home">
	<section class="home-offers">
		<div class="top-home">
			<div class="row container align-items-center">
				<div class="col-12">
					<h1 *ngIf="mostrar_titulo_movil; else escritorio">{{title_mobile}}</h1>
					<ng-template #escritorio>
						<h1>{{title}}</h1>
						<p class="descripcion-banner">Aplica a la oferta que quieras de manera segura.</p>
					</ng-template>
				</div>
			</div>
		</div>
		<div class="contHeader" *ngIf="!flagFilter">
			<div class="container">
				<h2>Encuentra lo que necesitas</h2>
				<hr class="sepDskp">
				<div class="box-carousel">
					<div *ngFor="let dat of arrayCategory" class="fichaMovile">
						<div *ngIf="dat.state == 1" [ngClass]="btnActive" class="hover-20">
							<div class="hover-overlay"></div>
							<a [routerLink]="['/categoria/', dat.url]" class="hover-1-content oferta_{{dat.id}}"
								title="{{dat.name}}">
								<div class="box-content oferta_{{dat.id}}" id="oferta_{{dat.id}}"
									(click)="envio_datalayer_iconos(dat.name)">
									<div class="iconBox oferta_{{dat.id}}">
										<img src="{{dat.icon}}" alt="Ofertas de crédito" title="{{dat.name}}"
											class="imgIcon oferta_{{dat.id}}" id="imgIcon{{dat.id}}" />
									</div>
									<div class="txtBox oferta_{{dat.id}}">
										<p class="hover-1-title oferta_{{dat.id}}" innerHTML="{{dat.name}}"></p>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div id='div-gpt-ad-1683582277702-0' style="margin-top: 24px;" class="commercial-banner"
				[ngClass]="checkBannerHome()">
			</div>
		</div>
		<h2 *ngIf="!flagFilter">¿Cómo funciona Buscacrédito?</h2>
		<hr class="sepDskp" *ngIf="!flagFilter">
		<div class="explainDskp container" *ngIf="!flagFilter">
			<div class="row">
				<div class="col-4 p-5">
					<img src="assets/icons/home/ofertas _ideales_BC.svg" appLazyLoadImg alt="Descubre todas las ofertas" title="Ofertas financieras según tu perfil crediticio con buscacrédito">
					<h3 class="titleDskp">1. Descubre todas las ofertas</h3>
					<p class="pDskp">Dentro del Marketplace filtra las ofertas según tus necesidades y características.
					</p>
				</div>
				<div class="col-4 p-5">
					<img src="assets/icons/home/aplicacion_gratis_BC.svg" appLazyLoadImg alt="Inicia sesión o registrate" title="Registrate aquí y aplica a tu oferta en Buscacrédito">
					<h3 class="titleDskp">2. Inicia Sesión o Registrate</h3>
					<p class="pDskp">Tu registro y autenticación ayudan a evitar el fraude por suplantación.</p>
				</div>
				<div class="col-4 p-5">
					<img src="assets/icons/home/nuevos_productos_BC.svg" appLazyLoadImg alt="Aplica a la oferta ideal" title="Ofertas financieras según tu perfil de crédito esperan por ti en Buscacrédito">
					<h3 class="titleDskp">3. Aplica a la oferta ideal</h3>
					<p class="pDskp">Finaliza el proceso con la entidad, y disfruta de tomar el control de tu vida
						financiera.</p>
				</div>
			</div>
		</div>
		<div id="carouselExampleIndicators" class="carousel explain" data-ride="carousel" *ngIf="!flagFilter">
			<div class="carousel-indicators">
				<p data-target="#carouselExampleIndicators" data-slide-to="0" class="btnCarousel active">&#8226;</p>
				<p data-target="#carouselExampleIndicators" data-slide-to="1" class="btnCarousel">&#8226;</p>
				<p data-target="#carouselExampleIndicators" data-slide-to="2" class="btnCarousel">&#8226;</p>
			</div>
			<div class="carousel-inner">
				<div class="carousel-item active">
					<img src="assets/icons/home/aplicacion_gratis_BC.svg" appLazyLoadImg alt="Descubre todas las ofertas" title="Ofertas financieras según tu perfil crediticio con buscacrédito" >
					<h3 class="titleExp">1. Descubre todas las ofertas</h3>
					<p class="pExp">Dentro del Marketplace filtra las ofertas según tus necesidades y características
					</p>
				</div>
				<div class="carousel-item">
					<img src="assets/icons/home/ofertas _ideales_BC.svg" appLazyLoadImg alt="Inicia sesión o registrate" title="Registrate aquí y aplica a tu oferta en Buscacrédito">
					<h3 class="titleExp">2. Inicia Sesión o Registrate</h3>
					<p class="pExp">Tu registro y autenticación ayudan a evitar el fraude por suplantación.</p>
				</div>
				<div class="carousel-item">
					<img src="assets/icons/home/nuevos_productos_BC.svg" appLazyLoadImg alt="Aplica a la oferta ideal" title="Ofertas financieras según tu perfil de crédito esperan por ti en Buscacrédito">
					<h3 class="titleExp">3. Aplica a la oferta ideal</h3	>
					<p class="pExp">Finaliza el proceso con la entidad, y disfruta de tomar el control de tu vida
						financiera.</p>
				</div>
			</div>
		</div>
	</section>
	<section *ngIf="!flagFilter">
		<div class="container testimonials">
			<h2>¿Qué dicen nuestros usuarios?</h2>
			<hr>
			<div class="box-all">
				<div class="box-people" *ngFor="let test of testimonials">
					<div class="icon-people">
						<img src="assets/icons/home/testimonial.svg" alt="Testimonios" title="Testimonios" appLazyLoadImg/>
					</div>
					<div class="qualify d-none d-md-block">
						<i class="fa fa-star" aria-hidden="true" id="1star"
							[ngClass]="validarEstrella(1, test.qualify)"></i>
						<i class="fa fa-star" aria-hidden="true" id="2star"
							[ngClass]="validarEstrella(2, test.qualify)"></i>
						<i class="fa fa-star" aria-hidden="true" id="3star"
							[ngClass]="validarEstrella(3, test.qualify)"></i>
						<i class="fa fa-star" aria-hidden="true" id="4star"
							[ngClass]="validarEstrella(4, test.qualify)"></i>
						<i class="fa fa-star" aria-hidden="true" id="5star"
							[ngClass]="validarEstrella(5, test.qualify)"></i>
					</div>
					<p>{{test.description}}</p>
					<div class="d-flex">
						<p class="author">{{test.author}}</p>
						<div class="qualify d-flex d-md-none">
							<i class="fa fa-star" aria-hidden="true" id="1star"
								[ngClass]="validarEstrella(1, test.qualify)"></i>
							<i class="fa fa-star" aria-hidden="true" id="2star"
								[ngClass]="validarEstrella(2, test.qualify)"></i>
							<i class="fa fa-star" aria-hidden="true" id="3star"
								[ngClass]="validarEstrella(3, test.qualify)"></i>
							<i class="fa fa-star" aria-hidden="true" id="4star"
								[ngClass]="validarEstrella(4, test.qualify)"></i>
							<i class="fa fa-star" aria-hidden="true" id="5star"
								[ngClass]="validarEstrella(5, test.qualify)"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>