import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

declare var googletag: any;

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  public utmParams: Params = {};
  public haveUtm: boolean = false;

  constructor(public route: ActivatedRoute, public router: Router) { }

  addAdsById(slot: string, id: string, size: number[][]) {
    setTimeout(() => {
      googletag.cmd.push(() => {
        var slotAdd = googletag.pubads().getSlots().find((x: any) => x.getSlotId().getId() == slot + "_0");
        if (slotAdd == undefined) {
          googletag.defineSlot(slot, size, id).addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
          googletag.display(id);
        } else {
          googletag.pubads().refresh([slotAdd]);
        }
      });
    }, 100);
  }

  /**
   * Método para inicializar el banner de Google Ad Manager (Banners dinámicos)
   * @param slot esta variable hace referencia al id_slot que tiene formato '/22857899675/###' es un string alfanúmerico
   * @param id esta variariable hace referencia al id_div que tiene formato 'div-gpt-ad-##########-#' es un string alfanúmerico
   */
  init_banner(slot: string, id: string){
    googletag.cmd.push(() =>{
      var slotAdd;
      // Verificar si el slot existe
      const check_slot = googletag.pubads().getSlots().find((id_slot: any) => id_slot.getSlotElementId() === id);
      // Si no existe se define y si existe refresca el slot
      if (check_slot === undefined){
        slotAdd = googletag.defineSlot(slot, [[728, 90], [300, 50]], id);
      } else{
        this.control_refresh(check_slot);
      }

      // Habilitar servicios
      if (slotAdd){
        slotAdd.addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
        // Mostrar anuncio
        googletag.display(id);
      } 
    });
  }

  /**
   * Esta función control el tiempo y las cantidades de veces que se refresca un slot
   * objt_slot_time: guarda el tiempo de la última vez que se refresco cada slot
   * cont_slot: me almacena el número de veces que ha excedido refrescar el slot
   * interval: Es el tiempo en que se va a refrescar el slot
   * max_limit: El número máximo de veces que puede volver a realizar el método 
   * @param id_slot slot definido
  */
  objt_slot_time: {[ key: string]: number } = {};
  cont_slot: {[ key: string]: number } = {};
  interval = 2000;
  max_limit = 3;
  control_refresh(id_slot: any, time_interval: number = this.interval) {
    const id_div = id_slot.getSlotElementId();
    const time_now = Date.now();

    if (!this.objt_slot_time[id_div] || time_now - this.objt_slot_time[id_div] > time_interval ){
      this.objt_slot_time[id_div] = time_now;
      googletag.pubads().refresh([id_slot]);
      this.cont_slot[id_div] = 0;
    } else if (this.cont_slot[id_div] < this.max_limit)  {
      setTimeout(() => {
        this.cont_slot[id_div] ++;
        this.control_refresh(id_slot, time_interval);
      }, time_interval);
    } else {
      console.error('Exceeded the maximum number to refresh');
    }
  }

  /**
   * Funcion que valida si el dispositivo es IOS
   * @returns si es IOS
   */
  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  currency(val: any) {
    let value: number;
    let currencySign: string = '$';
    let decimalLength: number = 0;
    let chunkDelimiter: string = '.';
    let decimalDelimiter: string = ',';
    let chunkLength: number = 3;

    value = parseInt(val);

    const result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    const num = value.toFixed(Math.max(0, ~~decimalLength));

    // tslint:disable-next-line: max-line-length
    return currencySign + (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
  }

  /**
   * Funcion que obtiene y guarda en el sessionstorage los parametros que contengan la utm en su llave
   */
  getUtmParams(setUrl:boolean = true) {
    this.utmParams = {};
    let params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    for (const key in params) {
      if (key.toLowerCase().includes('utm')) {
        this.utmParams[key] = params[key];
        this.haveUtm = true;
      }
    }
    if (Object.keys(this.utmParams).length !== 0 && this.haveUtm) {
      sessionStorage.setItem('utmParams', JSON.stringify(this.utmParams));
    } else {
      let sessionUtm = JSON.parse(sessionStorage.getItem('utmParams') as string);
      if (sessionUtm != null) {
        this.utmParams = sessionUtm;
        if (this.utmParams != null && setUrl) {
          // setTimeout(() => {
            this.setParams(this.utmParams);
          // }, 100)
        }
      }
    }
  }

  /**
   * Funcncio para agregar los parametros utm en la url actual
   * @param params Parametros utm para agregar en la url
   */
  setParams(params: Params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    }
    )
  }

  /**
   * Funcion para agregar estilos para casos de innherHtml
   */
  setStyleByCode(css:string) {
    // const css = 'a {color: pink;}';
    const head = document.getElementsByTagName('div')[0];
    const style = document.createElement('style');
    // style.type = 'text/css';
    // console.log(this.cssPos.replace(/(\r\n|\n|\r)/gm,''))
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
  }
}
