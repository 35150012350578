import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { LoadService } from 'src/app/core/services/load.service';
import { OfferService } from 'src/app/core/services/offer.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-landing-offer',
  templateUrl: './landing-offer.component.html',
  styleUrls: ['./landing-offer.component.scss']
})
export class LandingOfferComponent implements OnInit {
  public companyId: string | null = "";
  public offerId: string | null = "";
  public cellphone: string | null;
  public cookie = "";
  public offerUnique: any;
  public textScore = '';
  public id = 0;
  public urlLogin = environment.urlLogin;
  public btn_retomar_aplicacion = false;
  public cardForTC: boolean = false;
  // Objeto de configuracion de etiquetas de la oferta donde 0 es por defecto la tarjeta sin etiqueta
  public configLabel: any = {
    '0': { color: 'blue', img: '', showLabel: false },
    '1': { color: 'orange', img: 'assets/icons/oferta_destacada_estrella.svg', showLabel: true },
    '2': { color: 'green', img: 'assets/icons/icono_etiqueta_nueva.svg', showLabel: true },
    '3': { color: 'blue', img: 'assets/icons/icono_etiqueta_regular.svg', showLabel: true },
  }
  constructor(public broker: BrokerService, private cookieService: CookieService, private load: LoadService, private route: ActivatedRoute, private router: Router, public utilities: UtilitiesService, public offer: OfferService, public sesion: SesionService, private dataLayerService: DataLayersService) {
    this.sesion.header = 1;
    // router.events.subscribe((val) => {
    //   this.utilities.getUtmParams();
    // });
    this.cellphone = localStorage.getItem('userPhone');
  }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.offerId = this.route.snapshot.paramMap.get('offerId');
    this.cookie = this.sesion.sesionCookie;
    this.validateUser();
  }


  validateUser() {
    const data = {
      clientId: environment.clientId,
      idSession: this.sesion.sesionCookie,
      country: environment.country
    };
    this.load.open('#loader', '', '', false, undefined);
    this.broker.validar_sesion(data).subscribe({
      next: (res: any) => {
        this.sesion.isSessionValid = true;
        this.sesion.sesionCookie = this.cookieService.get('IDSESSIONMDC');
        this.sesion.nameSession = res.nombre;
        this.sesion.dataUser = res;
        this.sesion.userPhone = res.phone;
        this.cellphone = res.phone;
        this.getOffer();
      }, error: (error: any) => {
        // this.cookieService.delete('IDSESSIONMDC', this.sesion.sesionCookie);
        document.cookie = 'IDSESSIONMDC=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        this.sesion.sesionCookie = null;
        this.getOffer();
      }
    });
  }

  getOffer() {
    // console.log(this.cookieService.get('IDSESSIONMDC'));

    let json = {
      idSession: this.sesion.sesionCookie == null ? '' : this.sesion.sesionCookie,
      companyId: this.companyId,
      offerId: this.offerId
    }
    localStorage.removeItem('landingByOffer');
    this.broker.consultarDetalle(json).subscribe({
      next: (res: any) => {
        if (res.company != undefined) {
          let offers = [
            {
              company: res.company,
              offers: [
                res.offer
              ]
            }
          ];
          this.offerUnique = this.offer.convertOffer(offers)[0];
          this.offer.offersList = this.offer.convertOffer(offers);
          this.offer.offerSelected = this.offerUnique;
          this.id = this.offerUnique.company.id + this.offerUnique.offerId;
          this.setTextScore();
          setTimeout(() => {
            this.scoreProbability();
          }, 100);
          let isTC = this.offerUnique.detail.subcategories.filter((ele: any) => ele == 'TC')
          if (isTC.length > 0) {
            this.cardForTC = true;
          }
          this.sesion.dataOffer["companyId"] = this.offerUnique.company.id;
          this.sesion.dataOffer["offerId"] = this.offerUnique.offerId;
          this.sesion.dataOffer["companyName"] = this.offerUnique.company.name;
          this.sesion.dataOffer["productName"] = this.offerUnique.card.productName;
          this.sesion.dataOffer["landingByOffer"] = '1';
          this.load.close('#loader');
          setTimeout(() => {
            if (localStorage.getItem("companyId") != null && localStorage.getItem('offerId') != null) {
              let applyBefore = this.offer.offersList.find((ele: any) => ele.company.id == localStorage.getItem("companyId") && ele.offerId == localStorage.getItem('offerId'));
              if (applyBefore != undefined) {
                this.validateOfferInProcess(applyBefore);
              } else {
                this.load.open('#noOfertasModal', '', '', false, undefined);
              }
            }
          }, 1000)
        }
      },
      error: (error) => {
        if (error.error.message) {
          if (error.error.message.length > 0) {
            if (error.error.message[0] == 'Error generando detalle de oferta: El usuario no cumple con los criterios de las ofertas') {
              this.load.close('#loader');
              setTimeout(() => {
                this.load.open('#noOfertasModal', '', '', false, undefined);
                if (this.cookie == "") {
                  this.router.navigate(['/categoria/creditos']);
                } else {
                  this.router.navigate(['/ofertas']);
                }
              }, 1000);
            } else {
              this.router.navigate(['/error']);
              this.load.close('#loader');
            }
          } else {
            this.router.navigate(['/error']);
            this.load.close('#loader');
          }
        } else {
          this.router.navigate(['/error']);
          this.load.close('#loader');
        }
      }
    });
  }

  /**
   * Funcion para determinar que boton mostrar al usuario
   * @returns tipo de botn a mostrar 1 = aplicar oferta, 2 = retomar aplicacion, 3 = oferta aplicada
   */

  selectButton() {
    if (!this.offerUnique.isApplied) {
      return 1;
    } else if ((this.offerUnique.isApplied) && (this.offerUnique.detail.urlRedirect != undefined || this.offerUnique.urlRedirect != '')) {
      return 2;
    } else if (this.offerUnique.isApplied && this.offerUnique.detail.urlRedirect == undefined && this.offerUnique.urlRedirect == '') {
      return 3;
    } else {
      return 1;
    }
  }

  /**
   * Funcion que consume el servicio de datalayer y valida si la oferta es gratuita lo redirecciona a la pagina del aliado configurada, si no es gratuita valida si el usuario tinene sesion
   * si la tiene sesion abre el modal de confirmacion y si no guarda unos valores en el localstorage y lo redirecciona al sso
   */
  applyOffer() {
    // this.data.company.id, this.data.offerId, this.data.company.name, data.card.productName, this.data.decisorEngine
    localStorage.setItem('companyId', this.offerUnique.company.id);
    localStorage.setItem('offerId', this.offerUnique.offerId);
    localStorage.setItem('companyName', this.offerUnique.company.name);
    localStorage.setItem('productName', this.offerUnique.card.productName);
    localStorage.setItem('landingByOffer', '1');
    this.offer.offerSelected = this.offerUnique;
    // GA4 - evento data layer select_content que envía la información de la oferta seleccionada
    this.dataLayerService.dl_eventoGA4_content('select_content', this.offerUnique.company.name, this.offerUnique.offerId, this.offerUnique.card.productName);
    if (this.offerUnique.free == true) {
      let url = this.offerUnique.detail.urlRedirect;
      if (url.includes("https://") || url.includes("http://")) {
        window.location.href = url;
      } else {
        window.location.href = 'https://' + url;
      }
    } else {
      if (this.sesion.isIdsession()) {
        if (this.offerUnique.company.id == "901255144" && this.offerUnique.offerId == '6') {
          this.load.openBcConecta();
        } else {
          if (this.offerUnique.card.buttonId != undefined) {
            let idModal = '#confirmationModalV2';
            let buttonId = this.isButtonIdValid(this.offerUnique.card.buttonId)
            this.load.open(idModal, buttonId, '', false, undefined);
          }
        }

      } else {
        window.location.href = this.urlLogin;
      }
    }
  }

  /**
   * Funcion que dependiendo del aliado consume el servicio de /applyOffer 0 /davivienda, si llama a /applyOffer y el consumo es exitoso redirecciona 
   * a la pagina que responde el servicio
   */
  redirect() {
    this.btn_retomar_aplicacion = true;
    this.enviar_dl(this.offerUnique);
    const body = {
      idSession: this.sesion.sesionCookie,
      cellPhone: this.cellphone,
      country: "CO"
    }
    let dataOferta = {
      idSession: this.sesion.sesionCookie,
      companyId: this.offerUnique.company.id,
      offerId: this.offerUnique.offerId
    }
    if (this.offerUnique.company.id == "560034313") {
      // $("#noDav" + this.id).hide();
      // $("#noDav_M" + this.id).hide();
      let dataOfertaDav = {
        idSession: this.sesion.sesionCookie,
        userAgent: "",
        idDevice: ""
      }
      this.broker.aplicarOfertaDav(dataOfertaDav).subscribe((resultado: any) => {
        // location.href = resultado.body;
        if (resultado.body.includes("http")) {
          this.open_offer_page(encodeURI(resultado.body));
        } else {
          this.router.navigate(['/error']);
        }
        // if (resultado.statusCodeValue == 400) {
        // 	$("#noDav" + this.id).show();
        // 	$("#noDav_M" + this.id).show();
        //   }
      });
    }
    // else if (this.data.company.id == "901255144") {
    //   localStorage.setItem('companyId', this.data.company.id);
    //   localStorage.setItem('offerId', this.data.offerId);
    //   localStorage.setItem('companyName', this.data.company.name);
    //   localStorage.setItem('productName', this.data.card.productName);
    //   this.offerService.offerSelected = this.data;
    //   this.load.openBcConecta();
    // }
    else {
      this.broker.aplicarOferta(dataOferta).subscribe({
        next: (resultado: any) => {
          if (resultado.orderNumber) {
            this.offer.offerApplied = resultado;
            this.offer.offerSelected.isApplied = true;
            const urlRedirect = resultado.applyDetail.urlRedirect;
            const urlEncriptedData = resultado.applyDetail.urlEncriptedData;
            if (urlRedirect != "" || urlRedirect != undefined) {
              let urlRedirect = resultado.applyDetail.urlRedirect;

              // Solo para aliado Credissimo
              if (this.offerUnique.company.id == "901146443") {
                this.open_offer_page(urlRedirect + '?bon=' + resultado.orderNumber);
              }
              // Solo para aliado Solventa
              else if (this.offerUnique.company.id == "901255144") {
                this.broker.sendEncriptedData(body, urlEncriptedData + resultado.encryptedInfo);
                this.open_offer_page(urlRedirect);
              }
              // Para todos
              else {
                if (this.hasQueryString(urlRedirect)) {
                  urlRedirect = urlRedirect + '&lead=' + encodeURIComponent(resultado.encryptedInfo);
                }
                else {
                  urlRedirect = urlRedirect + '?lead=' + encodeURIComponent(resultado.encryptedInfo);
                }
                this.offerUnique.preselecta.urlRedirect = urlRedirect;
                this.open_offer_page(urlRedirect);
              }
            }
          } else {
            $("#noDav" + this.id).show();
            $("#noDav_M" + this.id).show();
          }
        },
        error: error => {
          this.errorService();
        }
      });
    }
  }


  /**
   * Funcion que cierra modal de error y redirecciona a pagina de error general
   */
  errorService() {
    this.load.close("#confirmationModal");
    this.router.navigate(['/error']);
  }

  /**
   * Funcion para validar si una url contiene querystring
   * @param url url a verificar
   * @returns si la url tiene formato con queryString
   */
  hasQueryString(url: any) {
    let reg = new RegExp('[?&][a-zA-Z]', 'g');
    let queryString = reg.exec(url);
    if (queryString != null) {
      return queryString.length > 0 ? true : false;
    }
    return false;
  }


  // Esté método abre la página del aliado cuando el usuario retoma la aplicación
  open_offer_page(url: string) {
    setTimeout(() => {
      window.location.href = url;
      setTimeout(() => {
        this.btn_retomar_aplicacion = false;
      }, 2500);
    }, 5000);
  }

  isButtonIdValid(buttonId: string) {
    return (typeof buttonId === 'string' ? buttonId : '0');
  }

  /**
 * Funcion que usa el score de la oferta para saber como pintar la linea de score en el detalle
 */
  scoreProbability() {
    let score = parseInt(this.offerUnique.detail.score);
    switch (score > 0) {
      case (score >= 150 && score <= 402):
        $(".bajo[id='" + this.id + "b']").addClass("activo");
        break;
      case (score >= 403 && score <= 676):
        $(".bajo[id='" + this.id + "b'], .medio-bajo[id='" + this.id + "mb']").addClass("activo");
        break;
      case (score >= 677 && score <= 748):
        $(".bajo[id='" + this.id + "b'], .medio-bajo[id='" + this.id + "mb'], .medio[id='" + this.id + "m']").addClass("activo");
        break;
      case (score >= 749 && score <= 804):
        $(".bajo[id='" + this.id + "b'], .medio-bajo[id='" + this.id + "mb'], .medio[id='" + this.id + "m'], .medio-alto[id='" + this.id + "ma']").addClass("activo");
        break;
      case (score >= 805 && score <= 950):
        $(".bajo[id='" + this.id + "b'], .medio-bajo[id='" + this.id + "mb'], .medio[id='" + this.id + "m'], .medio-alto[id='" + this.id + "ma'], .alto[id='" + this.id + "a']").addClass("activo");
        break;
    }
  }

  /**
  * Este evento enviará un data layer sobre la continuación de la aplicación "continue_application"
  * @param data_oferta información de la oferta
  */
  enviar_dl(data_oferta: any) {
    this.dataLayerService.dl_eventoGA4_content('continue_application', data_oferta.card.productName, data_oferta.offerId, data_oferta.company.name);
  }

  /**
   * Funcion que usa el score de la oferta para saber que texto de score mostrar en el detalle
   */
  setTextScore() {
    let score = parseInt(this.offerUnique.detail.score);
    switch (score > 0) {
      case (score >= 150 && score <= 402):
        this.textScore = "Bajo";
        break
      case (score >= 403 && score <= 676):
        this.textScore = "Medio bajo";
        break;
      case (score >= 677 && score <= 748):
        this.textScore = "Medio";
        break;
      case (score >= 749 && score <= 804):
        this.textScore = "Medio alto";
        break;
      case (score >= 805 && score <= 950):
        this.textScore = "Alto";
        break;
      default:
        this.textScore = '';
        break;
    }
  }

  /**
   * Funcion que verifica si hay una oferta a la que se intento aplicar sin estar logueado, si se encuentra la oferta
   * en la lista de ofertas de preselecta se abre el modal de actualizacion de datos y se cambia la la url para la categoria
   * y sub-categoria de la oferta seleccionada
   * @param offerInProcess oferta a validar
   */
  validateOfferInProcess(offerInProcess: any) {
    if (this.sesion.isIdsession()) {
      if (localStorage.getItem('companyId') != null && localStorage.getItem('offerId') != null) {
        if (!offerInProcess.isApplied) {
          if (this.offer.offerSelected.company.id == '901255144' && this.offer.offerSelected.offerId == '6') {
            this.load.openBcConecta();
          } else {
            this.load.open('#confirmationModalV2', '', '', false, undefined);
          }
        }
        this.offer.clearOfferlocalStorage();
      }
    }

  }

  /**
   * Funcion para validar que el labelType sea valido y si no lo es retorna una configuracion basica
   * @returns Objeto de configuracion de etiqueta
   */
  getConfigLabel() {
    if (this.configLabel[this.offerUnique.labelType] != undefined) {
      return this.configLabel[this.offerUnique.labelType];
    }
    return this.configLabel['0'];
  }

  backOffers() {
    if (this.cookie == "") {
      this.router.navigate(['/categoria/creditos']);
    } else {
      this.router.navigate(['/ofertas']);
    }
  }
}
