import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { OfferService } from './offer.service';
import { DataLayersService } from './data-layers.service';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class LoadService {
  title: any;
  text: any;
  closebtn: any;
  redirectOnClose: any;
  redirectSSO: any;
  micro: string = "none";
  microO: string = "none";
  enableButtons: string = 'block';
  cellphone: any;
  isConectaFail: boolean = false;

  //variables flujo buscacredito conecta
  public imgBcConectaDesktop = "";
  public imgBcConectaMobile = "";
  public imgsBcConecta = {
    'd_success': "assets/images/modals/d_bc_conecta_success.png",
    'm_success': "assets/images/modals/m_bc_conecta_success.png",
    'd_fail': "assets/images/modals/d_bc_conecta_fail.png",
    'm_fail': "assets/images/modals/m_bc_conecta_fail.png"
  }
  public isInProcess:boolean = false;
  public showBcConecta:boolean = false;

  constructor(private router: Router, public offer: OfferService,
    private dataLayerService: DataLayersService) {
    this.title = "";
    this.text = "";
    this.closebtn = false;
    this.redirectOnClose = "";
    this.cellphone = localStorage.getItem('userPhone');
    this.imgBcConectaDesktop = this.imgsBcConecta.d_success;
    this.imgBcConectaMobile = this.imgsBcConecta.m_success
  }

  close(modal: any, shorTimeout = false) {
    let time = 1000;
    if(shorTimeout){
      time = 100;
    }
    setTimeout(() => {
      this.title = '';
      this.text = '';
      this.closebtn = false;
      $(modal).modal('hide');
      $('.modal-backdrop').removeClass("show");
      $('.modal-backdrop').addClass("hide");
      $('body').removeClass("modal-open");
      $('body').css({ "padding-right": 0 });
      if (this.redirectOnClose != undefined) {
        this.redirectOnClose != "" ? this.redirectPage(this.redirectOnClose) : this.router.navigate([this.redirectOnClose]);

      }
    }, time);
  }

  open(modal: any, title: any, text: any, close: any, redirect: any) {
    this.title = title;
    this.text = text;
    this.closebtn = close;
    this.redirectOnClose = redirect;
    $(modal).modal({
      show: true,
      keyboard: false
    });
  }

  redirectPage(page: any) {
    if (page.includes("http")) {
      window.location.href = encodeURI(page);
    } else {
      this.router.navigate([this.redirectOnClose]);
    }
  }

  closeMicro() {
    this.micro = "none";
  }

  openMicro(text: any) {
    this.text = text;
    this.micro = "block";
  }
  closeMicroO() {
    this.microO = "none";
  }

  openMicroO(text: any) {
    this.text = text;
    this.microO = "block";
  }

  closePopUp(indicativo: string) {
    if (indicativo == 'btn') {
      this.dataLayerService.dl_eventoGA4_confirm_application(localStorage.getItem('productName'), localStorage.getItem('offerId'), localStorage.getItem('companyName'), 'Cancelar');
    }
    this.close("#confirmationModal");
    this.offer.clearOfferlocalStorage();
    this.offer.errorPreselecta = false;
  }

  errorService() {
    // this.load.close("#confirmationModal");
    // this.router.navigate(['/error']);
    this.offer.errorPreselecta = true;
    this.enableButtons = "block";
    this.closeMicro();
  }

  openBcConecta(){
    this.imgBcConectaDesktop = this.imgsBcConecta.d_success;
    this.imgBcConectaMobile = this.imgsBcConecta.m_success;
    this.open('#bcConectaModal', '', '', false, undefined);
    this.showBcConecta = true;
  }

  closeBcConecta(){
    this.imgBcConectaDesktop = this.imgsBcConecta.d_success;
    this.imgBcConectaMobile = this.imgsBcConecta.m_success;
    this.showBcConecta = false;
    this.close('#bcConectaModal',true);
    this.offer.clearOfferlocalStorage();
    this.offer.errorPreselecta = false;
    if(this.router.url.toString().includes('aliado') && this.isConectaFail){
      this.isConectaFail = false;
      this.router.navigate(['/ofertas']);
    }
  }

}
