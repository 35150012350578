<div class="container" *ngIf="dataContent">
    <div class="row">
        <div class="col-12">
            <h2>
                {{sesion.nameSession}}, queremos conocerte mejor, completa la siguiente información.
            </h2>
            <p class="sub-title">
                Ten en cuenta que esta quedará registrada en la aplicación con el aliado.
            </p>
        </div>
        <div class="col-12">
            <div>
                <p class="mb-0 mt-2">¿Qué monto necesitas?</p>
                <input type="text" class="input" placeholder="$0 COP" (input)="textInputNum($event.target)">
            </div>
            <div *ngIf="!correctAmount && amountRequired != ''">
                <p class="error">El monto propuesto se encuentra fuera del rango establecido por la entidad</p>
            </div>
        </div>
        <div class="col-12">
            <div>
                <p class="mb-0 mt-2">Estado/situación laboral</p>
                <div class="custom-radio box-check-">
                    <button (click)="checkInput($event.target)" value="{{actividades_economicas[1].tipo}}">{{actividades_economicas[1].texto}}</button>
                    <button (click)="checkInput($event.target)" value="{{actividades_economicas[2].tipo}}">{{actividades_economicas[2].texto}}</button>
                    <button (click)="checkInput($event.target)" value="{{actividades_economicas[4].tipo}}">{{actividades_economicas[4].texto_Alt}}</button>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="mb-10">
                <p class="mb-0 mt-2">Tu número celular</p>
                <input type="tel" pattern="^(?!.*(\d)\1{3,})(^(3)\d*$)" minlength="10" maxlength="10"
                    [(ngModel)]="sesion.userPhone" [ngClass]="{'is-valid':validPhone()}" class="form-control input">
            </div>
        </div>
        <div class="col-12 text-center">
            <button class="cta" [disabled]="!checkData()" (click)="activeResult()">Actualizar y continuar<i
                    class="fa fa-chevron-right" aria-hidden="true"></i></button>
            <button class="cta secondary" (click)="this.load.closeBcConecta()">Cancelar<i class="fa fa-chevron-right"
                    aria-hidden="true"></i></button>
        </div>
    </div>
</div>
<div class="container" *ngIf="resultContent">
    <div class="row">
        <div class="col-12">
            <h2 *ngIf="success" class="success">
                ¡Felicidades, {{sesion.nameSession}}!
            </h2>
            <h2 *ngIf="!success" class="error">
                ¡Lo sentimos!
            </h2>
            <p *ngIf="success" class="sub-title">
                Tu crédito ha sido <b>APROBADO</b>. Completa el desembolso en la página de <b>{{offer.offerSelected.company.name}}</b>.
            </p>
            <p *ngIf="!success" class="sub-title">
                No fue posible continuar con tu solicitud debido a las políticas crediticias de <b>{{offer.offerSelected.company.name}}.</b>
            </p>
            <p *ngIf="!success" class="sub-title">
                Te invitamos a conocer más ofertas disponibles para ti.
            </p>
        </div>
        <div *ngIf="success" class="col-12">
            <p class="mb-10 mt-2">Resumen de la aplicación:</p>
        </div>
        <div *ngIf="success" class="col-12">
            <div class="row">
                <div class="col-6">
                    <p class="sub-title mb-0">Recibes:</p>
                    <p class="big">{{utilities.currency(responseOK?.detail.amount)}}</p>
                </div>
                <div class="col-6">
                    <p class="sub-title mb-0">Paga en:</p>
                    <p class="big">{{responseOK?.detail.deadLine}} Días</p>
                </div>
            </div>
        </div>
        <div *ngIf="offer.offerSelected.detail.additionalBenefits && success" class="col-12">
            <div class="add-benefit">
                <img title="crown" class="crown" alt="corona-beneficio" src="assets/icons/Icono_corona_adicional.svg" />
                <div class="box-benefit">
                    <p class="descBenefit">{{offer.offerSelected.detail.textAdditionalBenefits}}</p>
                </div>
            </div>
        </div>
        <div class="col-12">
            <hr />
        </div>
        <div *ngIf="success" class="col-12 text-center">
            <button class="cta" (click)="goAllie()">Ir al desembolso<i class="fa fa-chevron-right"
                    aria-hidden="true"></i></button>
            <!-- <button class="cta secondary">Cancelar<i class="fa fa-chevron-right" aria-hidden="true"></i></button> -->
        </div>
        <div *ngIf="!success" class="col-12 text-center">
            <button class="cta" (click)="load.closeBcConecta()">Conocer más ofertas<i class="fa fa-chevron-right"
                    aria-hidden="true"></i></button>
            <!-- <button class="cta secondary">Cancelar<i class="fa fa-chevron-right" aria-hidden="true"></i></button> -->
        </div>
    </div>
</div>