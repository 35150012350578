import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BrokerService } from 'src/app/core/services/broker.service';
import { MetatagsService } from 'src/app/core/services/metatags.service';
import { OfferService } from 'src/app/core/services/offer.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { ModalService } from 'src/app/core/services/modal.service';
import { CookieService } from 'ngx-cookie-service';
import { LoadService } from 'src/app/core/services/load.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public testimonials: any[] = [];
  @Input() childMessage!: string;
  arrayCategory: any = [];
  btnActive: any = [];
  flagFilter: boolean = false;
  title = 'Oportunidades financieras con Buscacrédito según tu perfil crediticio';
  title_mobile = '¡Descubre oportunidades financieras a tu medida con Buscacrédito!';
  loginUrl: any;
  bannerHome = false;
  scroll: number = window.pageYOffset;
  mostrar_titulo_movil = false;

  constructor(
    public sesion: SesionService,
    private activateRoute: ActivatedRoute,
    public router: Router,
    public offer: OfferService,
    public broker: BrokerService,
    public metaServ: MetatagsService,
    private _title: Title,
    private _metaService: Meta,
    private cookieService: CookieService,
    public load: LoadService,
    public utilities: UtilitiesService,
    public modal: ModalService,
    private dataLayerService: DataLayersService) {

    this.loginUrl = environment.urlLogin;
    this.activateRoute.queryParams.subscribe((params): any => {
      if (params['homeType']) {
        localStorage.setItem('homeType', params['homeType']);
      }
    });

    this.mostrar_titulo_movil = window.innerWidth <= 768;

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if(val.url == "/" || val.url.toLocaleLowerCase().includes('utm')){
          this.utilities.getUtmParams();        
        }
      }
    });
  }

  // Mostrar título para móvil y Ocultar título para escritorio
  @HostListener('window:resize', ['$event'])
  onResize(event: any){
    this.mostrar_titulo_movil = event.target.innerWidth <= 768;
  }

  ngOnInit() {
    if (this.activateRoute.snapshot.paramMap.get('category') != null) {
      this.flagFilter = true;
    }
    this.setCategory();

    this._title.setTitle('Buscacrédito | Las mejores ofertas de créditos, inversión y más');
    this._metaService.updateTag({
      name: 'description', content: 'Busca y compara múltiples ofertas de créditos y muchos productos financieros más! Desembolsos completamente online y preaprobaciones gratis.'
    });
    this.metaServ.createCanonicaURL('https://buscacredito.midatacredito.com');
  }

  /**
   * Esta funcion se encarga de cerrar la sesion mediante el llamado del servicio /authn/signOff y limpiar la informacionmpara acceder al sitio
   */
  closeSession() {
    const body = {
      clientId: environment.clientId,
      idSession: this.sesion.sesionCookie,
      country: environment.country
    };
    this.broker.cerrar_sesion(body).subscribe((response: any) => {
      this.borrarData();
    },
      (error) => {
        this.borrarData();
      });
  }

  /**
   * FUncion que elimina informacion de ingreso, cookies y al final redirecciona al inicio 
   */
  borrarData() {
    window.localStorage.clear();
    this.cookieService.delete('IDSESSIONMDC', this.sesion.sesionCookie, environment.domain);
    this.sesion.sesionCookie = null;
    this.router.navigate(['/']);
  }

  /**
   * Esta funcion llama al archivo Ubicado en el cloud front de las categorias y carga la informacion
   */
  setCategory() {
    if (this.arrayCategory.length === 0) {
      this.broker.getMenu().subscribe((response: any) => {
        this.testimonials = response.testimonials;
        this.arrayCategory = response.categories;
        this.arrayCategory = this.arrayCategory.filter((element: any) => element.state == 1);
      });
    }
  }

  /**
   * Esta funcion valida cuantas estrellas mostrara en el testimonio
   * @param start posicion de la estrella
   * @param qualify numero de estrellas
   * @returns 
   */
  validarEstrella(start: any, qualify: any) {
    if (start <= qualify) {
      return "orange";
    } else {
      return "disabled";
    }
  }

  /**
   * Esta función envia un evento data layer por la categoria seleccionada en los iconos.
   * Envia el data layer "clic_icons_credit"
   * @param texto_boton texto del botón accionado 
   */
  envio_datalayer_iconos(texto_boton: string) {
    this.dataLayerService.dl_eventoGA4_menu('clic_icons_credit', texto_boton);
  }

  public checkDivHome = false;
  /**
   * Esta funcion valida si el banner no esxite para cargarlo nuevamente
   */
  checkBannerHome() {
    if (!this.checkDivHome) {
      let tamano = [[728, 90], [300, 50]];
      this.utilities.addAdsById(environment.slotsBanners.home, 'div-gpt-ad-1683582277702-0', tamano);
      this.checkDivHome = true;
    }
    return ''
  }
}
