<div class="container-fluid faq-main">
    <div class="faqHeader">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-7">
                    <div class="titFaqGral">
                        <h1 class="faqTitle">¿En qué podemos ayudarte?</h1>
                        <p class="faqDescription">En nuestra sección de preguntas frecuentes puedes resolver cualquier consulta, también encontrarás información sobre muchos temas.</p>
                        <hr/>
                    </div>
                </div>
                <div class="col-12 col-md-5 text-center">
                    <img src="assets/images/icono_preguntas_frecuentes.svg" class="faqImg" alt="Preguntas Frecuentes" title="Resuelve tus dudas, Buscacrédito">  
                </div>
            </div>
        </div>
    </div>
    <div id='div-gpt-ad-1717082860022-0' class="commercial-banner" [ngClass]="checkBannerFaq()"></div>
	<div class="container">
        <h2 class="title-faqs">Busca respuestas por categorías</h2>
        <div class="row box-faqs">
            <div class="col-12">
                <div itemscope itemtype="https://schema.org/FAQPage" class="accordion" id="faqAccordion">
                    <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" *ngFor="let pregunta of faqs; let j = index;">
                        <div class="card-header" id="faq{{j}}">
                            <button class="btn btn-link" type="button" data-toggle="collapse" [attr.data-target]="'#collapse'+j" aria-expanded="false" [attr.aria-controls]="'collapse'+j" (click)="changeType(j)">
                                <span itemprop="name">{{pregunta.pregunta}}</span>
                                <i class="fa fa-angle-right" id="moreIcon{{j}}"></i>                                
                            </button>
                        </div>                        
                        <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer"id="collapse{{j}}" class="collapse" [attr.aria-labelledby]="'faq'+j" [attr.data-parent]="'#faqAccordion'">
                            <div itemprop="text" class="card-body" [innerHTML] = "pregunta.respuesta">
                            </div> 
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    </div>
</div>