import { Injectable } from '@angular/core';
import { AppliedOffer, SelectedOffer } from '../models/offer.model';
import { BrokerService } from './broker.service';
import { environment } from 'src/environments/environment';
import { DataLayersService } from './data-layers.service';
import { LoadService } from './load.service';
import { CookieService } from 'ngx-cookie-service';
import { SesionService } from './sesion.service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  public offerSelected: any;
  public offerApplied: AppliedOffer;
  public offerRequirement: boolean = true;
  public categories: Array<any> = [];
  public categoriesActive: Array<any> = [];
  public categorySel: any;
  public subCategories: Array<any> = [];
  public subCategorySel: any;
  public filters: Array<any> = [];
  public filtersSel: Array<any> = [];
  public offersList: Array<any> = [];
  public offersFil: Array<any> = [];
  public errorPreselecta = false;
  public filtersApply: any = [];
  public title_h1 = 'Oportunidades financieras con Buscacrédito según tu perfil crediticio';
  public title_mobile_h1 = '¡Descubre oportunidades financieras a tu medida con Buscacrédito!';

  constructor(private broker: BrokerService, private dataLayerService: DataLayersService, public cookieService: CookieService, public sesion: SesionService) {

    this.offerSelected = { company: {}, offer: { id: 0, labelType: '0', textLabel: '', positionOffer: 0, card: { productName: '' }, detail: {}, terms: '', isApplied: false, decisorEngine: false }, status: '', preselecta: { orderNumber: '', applyDate: '', urlRedirect: '' } };
    this.offerApplied = { company: {}, applyDetail: {}, email: '', orderNumber: '', terms: '', applyDate: '', encryptedInfo: '' };
    this.setCategories();
    this.setFilters();

  };

  /**
   * Funcion que generar una nueva de lista de la ofertas de los servicios pero en solo array se incluye toda la informacion relevante
   * en un objeto de oferta y se agrega a la nueva lista
   * @param offersList lista de ofertas de los servicios
   * @param free variable para saber si la lista es de freeOffers
   * @returns ofertas convertidas para el uso del front
   */
  convertOffer(offersList: any, free = false) {
    let newOffersList: any = [];
    offersList.forEach((offer: any) => {
      offer.offers.forEach((companyOffer: any) => {
        let offerAuxTmp = { labelType: '0', textLabel: '', positionOffer: 0, card: {}, company: {}, detail: {}, offerId: 0, url: undefined, terms: '', timeOffer: '', isApplied: false, free: false, payOffer: false, decisorEngine: false, urlRedirect: '', preselecta: {}, idOffer: '' };
        offerAuxTmp.company = offer.company;
        offerAuxTmp.card = companyOffer.card;
        offerAuxTmp.isApplied = companyOffer.isApplied;
        offerAuxTmp.offerId = companyOffer.id;
        offerAuxTmp.timeOffer = "";
        offerAuxTmp.detail = companyOffer.detail;
        offerAuxTmp.terms = companyOffer.terms;
        offerAuxTmp.labelType = (companyOffer.labelType == null || companyOffer.labelType == "" ? '0' : companyOffer.labelType);
        offerAuxTmp.textLabel = companyOffer.textLabel;
        offerAuxTmp.positionOffer = companyOffer.positionOffer;
        (offerAuxTmp.url as any) = environment.urlLogin;
        offerAuxTmp.free = free;
        offerAuxTmp.payOffer = companyOffer.payOffer.statePayOffer;
        offerAuxTmp.decisorEngine = companyOffer.decisorEngine;
        offerAuxTmp.idOffer = companyOffer.idOffer
        if (companyOffer.applyDetail != null) {
          offerAuxTmp.urlRedirect = companyOffer.applyDetail.urlRedirect;
        } else {
          offerAuxTmp.urlRedirect = '';
        }
        newOffersList.push(offerAuxTmp);
      });
    });
    return newOffersList;
  }

  /**
   * Funcion que carga el archivo de categorias y carga las categorias activas
   */
  setCategories() {
    if (this.categories.length == 0) {
      this.broker.getMenu().subscribe((response: any) => {
        this.categories = response.categories;
        this.categoriesActive = this.categories.filter((element: any) => element.state == 1);
      });
    }
  }

  /**
   * Funcion que carga el archivo de filters para saber las configuracion de los filtros
   */
  setFilters() {
    if (this.filters.length == 0) {
      this.broker.getFilters().subscribe((response: any) => {
        this.filters = response.filters;
        // this.categoriesActive = this.categories.filter((element: any) => element.state == 1);
      });
    }
  }

  /**
   * Funcion que filtrar las ofertar deacuerdo al id del filtro seleccionado, si no viene id se filtra solo por categoria y sub-categoria
   * si viene el id del filtro verifica si ya se han realizado filtros buscando en la variable filtersApply para filtrar de nuevo despues
   * realiza el proceso de filtrado del filtro seleccionado y ordena las ofertas
   * @param idFilter id del filtro
   * @param val valor a filtrar
   */
  setFilterOffer(idFilter: number = 0, val: any = null) {
    // document.body.scrollTop = 0; // For Safari
    // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.offersFil = this.offersList;
    if (this.categorySel != undefined) {
      this.offersFil = this.offersList.filter((ele: any) => ele.detail.categoryId == this.categorySel.id);
    }
    if (this.subCategorySel != undefined) {
      this.offersFil = this.offersList.filter((ele: any) => ele.detail.subcategories == this.subCategorySel.acronyn);
    }
    if (idFilter != 0) {
      //Busca si se ya hay filtros aplicados para volver a filtrar y no perder lo anterior
      let otherFilter = this.filtersApply.filter((ele: any) => ele.id != idFilter);
      otherFilter.forEach((element: any) => {
        // this.setFilterOffer(element.id,element.val);
        switch (element.type) {
          case 'range':
            this.filterRange(element.id, element.val);
            break;
          case 'bool':
            this.filterBool(element.id, element.val);
            break;
          case 'check':
            this.filterCheck(element.id, element.val);
            break;
          case 'text':
            this.filterText(element.id, element.val);
            break;
          default:
            break;
        }
      });
      //Filtra el nuevo filtro seleccionado
      let filterSearch = this.filters.find(ele => ele.id == idFilter);
      switch (filterSearch.type) {
        case 'range':
          this.filterRange(idFilter, val);
          break;
        case 'bool':
          this.filterBool(idFilter, val);
          break;
        case 'check':
          this.filterCheck(idFilter, val);
          break;
        case 'text':
          this.filterText(idFilter, val);
          break;
        default:
          break;
      }
    }
    this.offersFil = this.sortOffer(this.offersFil)
  }

  /**
   * Funcion que actualiza los filtros que se han realizado para se mantengan todos, si el filtro ya existe lo actualiza
   * si no lo agrega
   * @param idFilter id del filtro
   * @param val valor a filtrar
   * @param type tipo del filtro
   */
  setActuallyFilter(idFilter: number, val: any = null, type: string) {
    if (this.filtersApply.length > 0) {
      let actuallyFilter = this.filtersApply.find((ele: any) => ele.id == idFilter);
      if (actuallyFilter == undefined) {
        let filterApply = {
          id: idFilter,
          type: type,
          val: val
        }
        this.filtersApply.push(filterApply);
      } else {
        actuallyFilter.val = val;
      }
    } else {
      let filterApply = {
        id: idFilter,
        type: type,
        val: val
      }
      this.filtersApply.push(filterApply);
    }
  }

  /**
   * Funcion que valida que el filtro seleecioanado conincida con las ofertas a filtrar, y si aplica filtra
   * el valor de acuerdo a el tipo de filtro range que usa los valores min y max
   * @param idFilter id del filtro
   * @param val valor a filtrar
   */
  filterRange(idFilter: number, val: any = null) {
    let offerFilterAux: any = [];
    this.offersFil.forEach(element => {
      if (element.detail.filters != undefined) {
        element.detail.filters.forEach((element2: any) => {
          if (element2.id == idFilter && (element2.values.min != undefined && element2.values.max != undefined)) {
            if ((element2.values.min <= val && element2.values.max >= val)) {
              offerFilterAux.push(element);
            }
          }
          if (element2.id == idFilter && (element2.values.min == undefined && element2.values.max == undefined)) {
            if ((Math.trunc(element2.values) <= val && Math.trunc(element2.values) >= val)) {
              offerFilterAux.push(element);
            }
          }
        });
      }
    });
    this.offersFil = offerFilterAux;
    this.setActuallyFilter(idFilter, val, 'range')
  }

  /**
   * Funcion que valida que el filtro seleecioanado conincida con las ofertas a filtrar, y si aplica filtra
   * el valor de acuerdo a el tipo de filtro bool que usa el valor values
   * @param idFilter id del filtro
   * @param val valor a filtrar
   */
  filterBool(idFilter: number, val: any = null) {
    if (val != "todas") {
      let offerFilterAux: any = [];
      this.offersFil.forEach(element => {
        if (element.detail.filters != undefined) {
          element.detail.filters.forEach((element2: any) => {
            if (element2.id == idFilter && (element2.values.toString() == val)) {
              offerFilterAux.push(element);
            }
          });
        }
      });
      this.offersFil = offerFilterAux;
    }
    this.setActuallyFilter(idFilter, val, 'bool')
  }

  /**
   * Funcion que valida que el filtro seleecioanado conincida con las ofertas a filtrar, y si aplica filtra
   * el valor de acuerdo a el tipo de filtro bool que usa el valor values
   * @param idFilter id del filtro
   * @param val valor a filtrar
   */
  filterCheck(idFilter: number, val: any = null) {
    if (val != "todas") {
      let offerFilterAux: any = [];
      this.offersFil.forEach(element => {
        if (element.detail.filters != undefined) {
          element.detail.filters.forEach((element2: any) => {
            if (element2.id == idFilter && (element2.values == val)) {
              offerFilterAux.push(element);
            }
          });
        }
      });
      this.offersFil = offerFilterAux;
    }
    this.setActuallyFilter(idFilter, val, 'check')
  }

  filterText(idFilter: number, val: any = null) {
    if (val != "todas") {
      let offerFilterAux: any = [];
      this.offersFil.forEach(element => {
        if (element.detail.filters != undefined) {
          element.detail.filters.forEach((element2: any) => {
            if (element2.id == idFilter && (element2.values.min != undefined && element2.values.max != undefined)) {
              if ((element2.values.min <= val && element2.values.max >= val)) {
                offerFilterAux.push(element);
              }
            }
            if (element2.id == idFilter && (element2.values.min == undefined && element2.values.max == undefined)) {
              if ((Math.trunc(element2.values) <= val && Math.trunc(element2.values) >= val)) {
                offerFilterAux.push(element);
              }
            }
          });
        }
      });
      this.offersFil = offerFilterAux;
    }
    this.setActuallyFilter(idFilter, val, 'text')
  }

  /**
   * Funcion que orderna la ofertas de menor a mayor teniendo en cuenta el campo positionOffer si este viene en 0 las ordena de manera aleatoria
   * @param listOffers lista de ofertas
   * @returns lista de ofertas ordenada
   */
  sortOffer(listOffers: Array<any>) {
    let offerRandom = listOffers.filter(ele => ele.positionOffer == 0) as never[];
    let offerPosition = listOffers.filter(ele => ele.positionOffer > 0) as never[];
    offerRandom = offerRandom.sort(() => { return Math.random() - 0.5 });
    offerPosition.sort((a: any, b: any) => Number(a.positionOffer) - Number(b.positionOffer));
    return [].concat(offerPosition, offerRandom);
  }

  /**
   * Funcion para limpiar del local storage la informacion de una oferta seleccionada
   */
  clearOfferlocalStorage(){
    localStorage.removeItem("offerId");
    localStorage.removeItem("companyId");
    localStorage.removeItem("modal");
    localStorage.removeItem("companyName");
    localStorage.removeItem("productName");
    localStorage.removeItem("landingByOffer");
  }

}