import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { MetatagsService } from 'src/app/core/services/metatags.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public faqs = [
    {
      "pregunta" : "¿Cómo funciona Buscacrédito?",
      "respuesta" : 
      `<span> Buscacrédito es un Marketplace que le permite al ciudadano buscar y comparar al instante y sin ningún costo diferentes ofertas y servicios financieros de forma personalizada. En la parte superior del sitio el ciudadano podrá visualizar las categorías con las que Buscacrédito tiene ofertas disponibles y filtrar las ofertas según su criterio de búsqueda; una vez el ciudadano elija una de ellas le solicitaremos acceder, escoger y aplicar a la oferta. Cada oferta publicada le permitirá al ciudadano conocer las condiciones y los beneficios que puede llegar a tener por aplicar. </span>`
    },
    {
      "pregunta" : "¿Qué beneficios tengo al ser usuario de Buscacrédito?",
      "respuesta" : 
      `<ul>
        <li>Rápido y fácil: Poder consultar y acceder a las ofertas en pocos pasos y sin costo.</li>
        <li>Confianza: Tener el respaldo y sentirse seguro de una gran marca con el tratamiento de los datos personales como Experian.</li>
        <li>Educación financiera: A través de contenidos buscamos que los ciudadanos desarrollen valores, conocimientos y las competencias necesarias para tomar decisiones de manera responsable.</li>
      </ul>`
    },
    {
      "pregunta" : "¿Qué tipos de ofertas puedo encontrar en Buscacrédito?",
      "respuesta" : 
      `<span>Buscacrédito tiene una variedad amplia de ofertas para ofertar con los ciudadanos; tarjetas de crédito, créditos, seguros, pensiones y cesantías, entre otros, hacen parte de la propuesta del Marketplace.</span>`
    },
    {
      "pregunta" : "¿Cómo aplicar a una oferta?",
      "respuesta" : 
      `<ul>
        <li>Desde el Home www.buscacredito.midatacredito.com el usuario filtra y elige la oferta de su preferencia.</li>
        <li>Luego es redirigido al módulo de registro o inicio de sesión, los mismos datos de acceso que el ciudadano ya tiene para ingresar a www.midatacredito.com. En caso de no tener este acceso, debe crear una cuenta en la plataforma ingresando por la opción “Regístrate”, diligenciando la información solicitada.</li> 
        <li>Luego de que el ciudadano ingresa, podrá continuar con la aplicación a la oferta, deberá confirmar su información y hacer clic en “Actualizar y continuar”. </li>
        <li>En caso de que el usuario no cumpla con los requerimientos específicos de la oferta a la que quería aplicar, será redirigido al apartado de las ofertas a que sí puede aplicar.</li>
        <li>Una vez que la aplicación sea terminada con éxito, el ciudadano visualizará el resumen de la aplicación y será redirigido a la pagina web del aliado dónde podrá terminar la aplicación de la oferta.</li>
        <li>Al correo del usuario, llega la confirmación de la aplicación.</li>      
      </ul>`
    },
    {
      "pregunta" : "¿Qué pasa cuando aplicas una oferta?",
      "respuesta" : 
      `<span>Al aplicar a una oferta, el ciudadano será direccionado a la página o a la aplicación del dueño de la oferta para que finalice el proceso definido y solicite el beneficio. En algunos casos, el ciudadano será contactado por el dueño de la oferta para finalizar el proceso y recibir el beneficio, esto depende de cada aliado, así como la aprobación y el desembolso o entrega del producto solicitado.</span>`
    },
    {
      "pregunta" : "¿Por qué no me aparecen ofertas?",
      "respuesta" : 
      `<span>Una vez se realice la verificación respectiva de la información registrada por el ciudadano, podrá visualizar y comparar ofertas en línea, siempre y cuando esta cumpla con las reglas y las políticas financieras establecidas por cada entidad. </span>`
    },
    {
      "pregunta" : "¿Cómo finalizar el proceso de aplicación a una oferta?",
      "respuesta" : 
      `<span>Después de que el ciudadano aplique a una oferta, se le brindarán las instrucciones del siguiente paso para dar continuidad con su solicitud.</span>`
    },
    {
      "pregunta" : "¿A cuántas ofertas puedo aplicar?",
      "respuesta" : 
      `<span>El ciudadano puede aplicar a cuantas ofertas considere pertinente, siempre teniendo en cuenta que el proceso de aprobación y el desembolso o entrega del producto solicitado depende de cada aliado. </span>`
    },
    {
      "pregunta" : "No completé el proceso, ¿puedo retomar la aplicación?",
      "respuesta" : 
      `<span>Ingresando de nuevo al Marketplace el usuario verá ofertas aplicadas con el botón “Retomar aplicación” allí será redireccionado a la página o a la aplicación del aliado y completar allí la solicitud.</span>`
    },
    {
      "pregunta" : "¿Se generan huellas de consulta?",
      "respuesta" : 
      `<span>Cada vez que un ciudadano aplique a una de las ofertas de crédito en la plataforma, estas no generarán registros en su historial crediticio. </span>`
    },
    {
      "pregunta" : "¿Las ofertas son de Buscacrédito?",
      "respuesta" : 
      `<span>Buscacrédito es una herramienta de comparación de ofertas, no es originador ni otorgador de los beneficios, ofertas expuestas en el Marketplace.</span>`
    }
  ];
  public checkDivFaq = false;

  constructor(private _title: Title, private metatagsServ: MetatagsService, public sesion: SesionService, private dataLayerService: DataLayersService,
              private utilities: UtilitiesService) { }

  ngOnInit(): void {
    this._title.setTitle('Preguntas frecuentes - Buscacrédito');
    this.metatagsServ.createCanonicaURL('https://buscacredito.midatacredito.com/preguntas-frecuentes');

    // GA4 - user
    if(this.sesion.sesionCookie){
      this.dataLayerService.dl_userId('.', 'logueado');
    }else{
      this.dataLayerService.dl_userId(null, 'anonimo');
    }
   }

  changeType(num: any) {
    if ($('#moreIcon' + num).hasClass('fa-angle-right')) {
      $('.btn-link > i').removeClass();
      $('.btn-link > i').addClass('fa fa-angle-right');
      $('#moreIcon' + num).toggleClass('fa fa-angle-right fa fa-angle-down');
    } else if ($('#moreIcon'+ num).hasClass('fa-angle-down')) {
      $('#moreIcon' + num).toggleClass('fa fa-angle-down fa fa-angle-right');
    }
  }

  
  /**
   * Funcion para validar si el div de google ads ya renderizo y asi llamar a la funcion de addPublidByID
   */
  checkBannerFaq() {
    if (!this.checkDivFaq) {
      let tamano = [[728, 90], [300, 250]];
      this.utilities.addAdsById(environment.slotsBanners.faq, 'div-gpt-ad-1717082860022-0', tamano);
      this.checkDivFaq = true;
    }
    return ''
  }

}
