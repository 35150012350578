<div class="modal fade hide" id="notRequirementModal" tabindex="-1" role="dialog"
	aria-labelledby="notRequirementModalLabel" aria-hidden="true">
	<div class="modal-dialog notRequirementModal modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<div class="container">
					<button class="btn-close" (click)="closeModalNotRequi()">
						<span aria-hidden="true">&times;</span>
					</button>
					<div class="row">
						<div class="col-12">
							<img src="assets/images/modals/Score_no_alcanza.svg" class="img-plan"
								alt="El score no alcanza" title="El score no alcanza" />
						</div>
						<div class="col-12 text-center">
							<h2>¡Lo sentimos!</h2>
							<p>No cumples con los criterios necesarios para acceder a esta oferta.</p>
							<p>Te invitamos a conocer todas las ofertas, a las que puedes aplicar.</p>
							<button class="btn-modal" (click)="closeModalNotRequi()">Ver ofertas <i
									class="fa fa-angle-right"></i></button>
							<p class="sub-text">
								O si lo necesitas, acá puedes saber <a href="https://www.midatacredito.com"
									 title="Midatacrédito">cómo mejorar tu score y el acceso a
									créditos.</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Modal Terms  -->
<div class="modal fade hide" id="termsAndConditions" tabindex="-1" role="dialog" aria-labelledby="termsAndConditionsModalLabel" aria-hidden="true">
	<div class="modal-dialog claimsModal modal-dialog-centered" role="document">
		<div class="modal-content">
			<!-- <div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div> -->
			<div class="modal-body">
				<div class="container">
					<div class="row">
						<div class="col-12 col-md-5 align-self-center">
							<img src="assets/images/modals/Aceptacion-filtro-Buscacredito.svg" alt="Aceptación de términos" title="Aceptación de términos" class="img-claim"/>
						</div>
						<div class="col-12 col-md-7">
							<h2>AUTORIZACIÓN TRATAMIENTO DE INFORMACIÓN</h2>
							<div *ngIf="step1">
								<div class="box">
									<div class="box-scroll">
										<p>Como Titular de mi información autorizo expresamente a Experian Colombia S.A., identificada con NIT. 900.422.614-8, a acceder a mi información financiera, crediticia, comercial, de servicios y proveniente de terceros países que se encuentra dentro de la base de datos de Datacrédito Experian (Operador de información) y tratarla para finalidades relacionadas con la validación de los parámetros establecidos por los Suscriptores y/o Aliados de Experian Colombia S.A. para aplicar a las ofertas de crédito publicadas por estos a través del servicio de Buscacrédito que suministra Experian Consumer Services – ECS como unidad de negocio de Experian Colombia S.A. Declaro conocer que, en caso de que como Titular no autorice a Experian Colombia S.A. a acceder a mi información financiera y crediticia, no podré conocer y aplicar a las ofertas publicadas por este medio dado que son necesarios para la prestación del servicio de Buscacrédito, e inmediatamente se cerrará la sesión iniciada en esta plataforma. </p>										
										<p>Así mismo, autorizo a Experian Colombia S.A., como Responsable del Tratamiento a que (i) comparta, con los Suscriptores y/o Aliados dueños de la(s) oferta(s) a la(s) que manifieste mi interés, mi nombre, tipo y número de identificación y datos de contacto, registrados dentro de mi perfil de MIDATACREDITO, para fines de control y gestión de la oferta seleccionada; y a que (ii) solicite y reciba de los Suscriptores y/o Aliados dueños de la(s) oferta(s) a la(s) que manifieste mi interés, los datos por ellos recolectados como lo son mi nombre, tipo y número de identificación, el producto, monto que me fue otorgado al aplicar la oferta publicada en Buscacrédito, y la fecha de aprobación,  así como el estado en que se encuentra la oferta y el desembolso  (pendiente, no efectivo y efectivo), con el fin de que Experian Colombia S.A. pueda gestionar aspectos administrativos y de facturación, medir la calidad y efectividad del servicio de Buscacrédito, realizar análisis estadísticos y de mercado, identificar los intereses de los usuarios (Titulares de los datos) que acceden al producto Buscacrédito y el valor agregado que le ha dado el producto Buscacrédito a los Suscriptores y/o Aliados de Experian Colombia S.A., para generar mejoras al producto Buscacrédito a favor de los Titulares de la Información y de los Suscriptores y/o Aliados.</p>
										<p> Conozco que, como titular de los datos registrados en mi perfil de MIDATACRÉDITO, tengo derecho a conocerlos, actualizarlos o rectificarlos, suprimirlos o eliminarlos de las bases de datos de MIDATACRÉDITO - EXPERIAN COLOMBIA, lo cual solo procederá en caso de que no se tenga una obligación legal o contractual para mantenerla, así como solicitar prueba de la presente autorización o revocarla, contactándome por medio del canal de contacto de la página web <a href="http://www.midatacredito.com" >www.midatacredito.com</a>, en las oficinas de Experian Colombia ubicadas en la Carrera 7 # 76 – 35 o en su Centro de Experiencia ubicado en la Avenida Américas # 62-84 Piso 2 Local 71-72; y que para mayor información sobre el tratamiento de mi información personal, puedo acceder a la Política de Tratamiento de Información Personal de Experian Consumer Service. <a href="https://www.midatacredito.com/politicas-privacidad" >https://www.midatacredito.com/politicas-privacidad</a>
										</p>
									</div>
								</div>
								<div class="box-btn column-btn">
									<button class="btn-midc btn-orange h-lg-btn w-100-btn" id="termsAndConditionsBC" (click)="saveTerms()" data-dismiss="modal" aria-label="Close">
										<span>Autorizo</span>
										<i class="fa fa-chevron-right" aria-hidden="true"></i>
									</button>
									<button class="link-btn" (click)="steptyC(1)">
										No autorizo
									</button>
								</div>								
							</div>
							<div *ngIf="step2">
								<div class="box-simply">
									<p>Conozco que en caso de que como Titular no autorice a Experian Colombia S.A. a acceder a mi información, no podré conocer y aplicar a las ofertas publicadas por este medio dado que es necesaria para la prestación del servicio de BuscaCrédito, e inmediatamente se cerrará la sesión iniciada en esta plataforma.</p>
								</div>
								<div class="box-btn">
									<button class="link-btn-outline dis-50 h-lg-btn" (click)="closeSession()">
										No acepto
										<i class="fa fa-times" aria-hidden="true"></i>
									</button>
									<button class="btn-midc btn-orange h-lg-btn dis-50" (click)="steptyC(2)" data-dismiss="modal" aria-label="Close">
										Volver
										<i class="fa fa-check" aria-hidden="true"></i>
									</button>									
								</div>								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>                        

<!--MODAL COMENTARIOS MIS APLICAIONES-->
<div class="modal fade hide" id="comments" tabindex="-1" role="dialog" aria-labelledby="commentsLabel"
	aria-hidden="true">
	<div class="modal-dialog commentsModal modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<div class="container">
					<button class="btn-close" (click)="closeModalComments()">
						<span aria-hidden="true">&times;</span>
					</button>
					<div class="row">
						<div class="col-12">
							<h2>Cuéntanos tu experiencia</h2>
						</div>
						<div class="col-12 text-center">
							<p>Tu opinión es importante para nosotros y nos ayuda a mejorar.</p>
							<div class="box-calification">
								<button class="sel"><img src="assets/icons/Ico-Info.svg" alt="información buscacrédito"/></button>
								<button><img src="assets/icons/Ico-Info.svg" alt="información buscacrédito" /></button>
								<button><img src="assets/icons/Ico-Info.svg" alt="información buscacrédito" /></button>
								<button><img src="assets/icons/Ico-Info.svg" alt="información buscacrédito" /></button>
							</div>
							<div class="box-comment">
								<textarea placeholder="Cuentanos tu experiencia..."></textarea>
							</div>
							<div class="box-contacted">
								<p>Deseas ser contactado.</p>
								<div>
									<input type="radio" id="contactedYes" name="contacted" checked /><label
										for="contactedYes">Si</label>
								</div>
								<div>
									<input type="radio" id="contactedNo" name="contacted"><label
										for="contactedNo">No</label>
								</div>
							</div>
							<button class="btn-modal" (click)="closeModalComments()">Enviar<i class="fa fa-angle-right"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Modal Terms  -->
<!--MODAL COMENTARIOS MIS APLICAIONES-->
<!--MODAL CONFIRMACION MODAL-->
<div class="modal fade hide" id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="confirmationModalLabel"
	aria-hidden="true" data-backdrop="static">
	<div class="modal-dialog confirmationModal modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<div class="container">
					<div class="row">
						<div class="col-12 col-md-6 order-0 order-md-0 mb-3 align-self-center">
							<img src="assets/images/modals/IMG-modalBC.svg" class="img-plan"
								alt="Confirmación de datos" title="Confirmación de datos" />
						</div>
						<div class="col-12 col-md-6 order-1 order-md-1">
							<h2>Confirma tus datos de contacto</h2>
							<p>Queremos que estés seguro que tus datos están correctos. Ten en cuenta que estos serán el
								canal de comunicación con el aliado.</p>
							<form autocomplete="off" novalidate #datos="ngForm">
								<div class="form-group">
									<label for="cellphone">Tu número celular</label>
									<input class="form-control" placeholder="Celular" minlength="10" maxlength="10"
										type="tel" class="form-control" id="celular" pattern="^(?!.*(\d)\1{3,})(^(3)\d*$)"
										required="required" [(ngModel)]="sesion.userPhone"
										#celular="ngModel" name="celular"
										[ngClass]="{'is-valid': celular.valid, 'is-invalid': ( celular.errors?.['required'] && celular.touched ) }">
								</div>
								<div [ngStyle]="{'display': load.enableButtons }">
									<button [attr.disabled]="datos.valid ? null : 'disabled'"
										[ngClass]="{'disabled': !datos.valid }" type="submit"
										class="btn-mrkt btn-green h-lg-btn w-100-btn apply_{{load.title}}" id="actualizarOferta" (click)="updateUserInfo()">
										Actualizar y continuar
										<i class="fa fa-chevron-right apply_{{load.title}}" aria-hidden="true"></i>
									</button>
									<button class="btn-mrkt btn-outline-blue h-lg-btn w-100-btn cancelar_{{load.title}}" id="cancelarOferta" (click)="load.closePopUp('btn')">
										Cancelar
										<i class="fa fa-chevron-right cancelar_{{load.title}}" aria-hidden="true"></i>
									</button>
								</div>
							</form>
							<div *ngIf="offerService.errorPreselecta" class="w-100" id="applyFail">
								<div class="errorApply">En este momento no es posible aplicar a esta oferta. Por favor inténtalo más tarde.</div>
							</div>
						</div>
					</div>
					<app-micro-loader></app-micro-loader>
				</div>
			</div>
		</div>
	</div>
</div>
<!--MODAL CONFIRMACION MODAL-->
<!--MODAL CONFIRMACION MODAL V2-->
<div class="modal fade hide" id="confirmationModalV2" tabindex="-1" role="dialog" aria-labelledby="confirmationModalV2Label"
	aria-hidden="true" data-backdrop="static">
	<div class="modal-dialog confirmationModal modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<div class="container">
					<button class="btn-close" (click)="closeModalConfirmationV2()">
						&times;
					</button>
					<div class="row">
						<div class="col-6 left-side d-none d-md-block">
							<img src="assets/images/modals/img_modal_confirmacion_v2.png" class="img-plan"
								alt="Confirmación de datos" title="Confirmación de datos" />
						</div>
						<div class="col-12 left-side d-block d-md-none">
							<img src="assets/images/modals/img_modal_confirmacion_v2_mobile.png" class="img-plan"
								alt="Confirmación de datos" title="Confirmación de datos" />
						</div>
						<div class="col-12 col-md-6 right-side">
							<h2>Confirma tus datos de contacto.</h2>
							<p>Queremos que estés seguro que tus datos estén correctos, ten en cuenta que estos serán el canal de comunicación con el aliado.</p>					
							<form autocomplete="off" novalidate #datos="ngForm">
								<div class="form-group" [ngStyle]="{'display': load.enableButtons }">
									<label for="cellphone">Tu número celular</label>
									<input class="form-control" placeholder="Celular" minlength="10" maxlength="10"
										type="tel" class="form-control" id="celular" pattern="^(?!.*(\d)\1{3,})(^(3)\d*$)"
										required="required" [(ngModel)]="sesion.userPhone"
										#celular="ngModel" name="celular"
										[ngClass]="{'is-valid': celular.valid, 'is-invalid': ( celular.errors?.['required'] && celular.touched ) }">
								</div>
								<div [ngStyle]="{'display': load.enableButtons }">
									<button [attr.disabled]="datos.valid ? null : 'disabled'"
										[ngClass]="{'disabled': !datos.valid }" type="submit"
										class="btn-mrkt btn-green h-lg-btn w-100-btn apply_{{load.title}}" id="actualizarOferta" (click)="updateUserInfo()">
										Actualizar y continuar
										<i class="fa fa-chevron-right apply_{{load.title}}" aria-hidden="true"></i>
									</button>
									<button class="btn-mrkt btn-outline-blue h-lg-btn w-100-btn cancelar_{{load.title}}" id="cancelarOferta" (click)="closeModalConfirmationV2()">
										Cancelar
										<i class="fa fa-chevron-right cancelar_{{load.title}}" aria-hidden="true"></i>
									</button>
								</div>
								<app-micro-loader></app-micro-loader>
								<div *ngIf="offerService.errorPreselecta" class="w-100" id="applyFail">
									<div class="errorApply">En este momento no es posible aplicar a esta oferta. Por favor inténtalo más tarde.</div>
								</div>
							</form>
							<!-- <button class="btn-modal">Conocer más ofertas<i class="fa fa-chevron-right" aria-hidden="true"></i></button> -->
						</div>
					</div>					
				</div>
			</div>
		</div>
	</div>
</div>
<!--MODAL CONFIRMACION MODAL V2-->
<!--MODAL NO OFERTAS-->
<div class="modal fade hide" id="noOfertasModal" tabindex="-1" role="dialog" aria-labelledby="noOfertasModalLabel"
	aria-hidden="true" data-backdrop="static">
	<div class="modal-dialog confirmationModal modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<div class="container">
					<button class="btn-close" (click)="closeModalNoOFertas()">
						&times;
					</button>
					<div class="row">
						<div class="col-6 left-side d-none d-md-block">
							<img src="assets/images/modals/img_modal_no_cumple.png" class="img-plan"
								alt="¡Lo sentimos!" title="¡Lo sentimos!" />
						</div>
						<div class="col-12 left-side d-block d-md-none">
							<img src="assets/images/modals/img_modal_no_cumple_mobile.png" class="img-plan"
								alt="¡Lo sentimos!" title="¡Lo sentimos!" />
						</div>
						<div class="col-12 col-md-6 right-side">
							<h2>¡Lo sentimos!</h2>
							<p>No fue posible continuar con tu solicitud debido a que <b>no cumples</b> con los criterios necesarios para acceder a esta oferta.</p>
							<p>Te invitamos a conocer más <b>ofertas disponibles</b> para ti.</p>
							<hr/>
							<button class="btn-modal" (click)="closeModalNoOFertas()">Conocer más ofertas<i class="fa fa-chevron-right" aria-hidden="true"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--MODAL NO OFERTAS-->
<!--MODAL BUSCACREDITO CONECTA-->
<div class="modal fade hide" id="bcConectaModal" tabindex="-1" role="dialog" aria-labelledby="bcConectaModalLabel"
	aria-hidden="true" data-backdrop="static">
	<div class="modal-dialog bcConectaModal modal-dialog-centered" role="document">
		<div class="modal-content">
			<div [ngClass]="{'d-none':load.isInProcess}" class="container">
				<div class="row" *ngIf="load.showBcConecta" >
					<div class="col-6 img-bc-conecta d-none d-md-block" [style]="'background-image: url(./../../../'+load.imgBcConectaDesktop+');'">
					</div>
					<div class="col-12 img-bc-conecta d-block d-md-none" [style]="'background-image: url(./../../../'+load.imgBcConectaMobile+');'">
					</div>
					<div class="col-md-6 co-12 content-bc-connect">
						<button class="btn-close" (click)="this.load.closeBcConecta()">
							<span aria-hidden="true">&times;</span>
						</button>
						<app-bc-connect></app-bc-connect>
					</div>
				</div>
			</div>
			<app-loader *ngIf="load.isInProcess" [isModal]="false"></app-loader>
		</div>
	</div>
</div>
<!--MODAL BUSCACREDITO CONECTA-->